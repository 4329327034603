/**App.scss for app styling */

.grid-container {
	height: cal(100vh- 132px); //subtract toolbar heights
}

.map-container {
	position: relative;
	height: calc(100vh - 132px);
}

.statewide-chart-grid-container,
.site-chart-grid-container {
	height: 100%;
	padding-bottom: 64px;
}

.site-chart-chip {
	padding-top: 20px !important;
}

#map {
	height: 100%;
	width: 100%;
}

.sites-map-legend {
	position: absolute;
	top: 2%;
	right: 2%;
	width: 16rem;
}

.sites-legend-iconButton {
	padding: 0 !important;
}

.sites-legend-icon {
	font-size: 0.75rem !important;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}

.dashboardOverview, 
.sitesLayout {
	height: calc(100vh - 132px);
	overflow: auto;
}

.sitesLayout {
	padding: 1rem;
}

/** in-page links */

.dashboardOverview a, 
.sitesLayout a {
	text-decoration: auto;
	color: #14375a;
	font-weight: 500;
}

.dashboardOverview-topDivider,
.sitesLayout-topDivider {
  margin: 1rem !important;
}

/** Navigation Links */

.menu-button:hover {
	color: #d3d3ce !important;
}

.menu-button-active {
	color: #d3d3ce !important;
}

/** Material UI table headers hover and active styles */

.MuiTableHead-root .MuiTableCell-root span:hover {
	color: #d3d3ce !important;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active {
	color: #d3d3ce !important;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
	color: #d3d3ce !important;
}

/** CSS settings for the bottom toolbar */

.optionsBar {
	position: fixed;
	bottom: 0.01%;
	left: 0;
	right: 0;
	align-items: center;
	z-index: 999;
	height: 64px;
}

.optionsBar--toolbar {
	position: relative;
	background-color: #eaeae6;
}

.optionsBar--gridContainer {
	align-items: center;
	grid-template-columns: repeat(12, 1fr);
}

.optionsBar--gridItem {
	padding: 0.2rem 0.3rem 0.1rem 0.1rem;
}

.optionsBar--scrollTop {
	position: fixed;
	bottom: 2%;
	right: 2%;
	cursor: pointer;
	background-color: #02404e;
	border-radius: 50%;
	color: white;
	font-size: 2.1rem !important;
	z-index: 1000;
}
